import React from 'react';
// eslint-disable-next-line import/no-cycle
import SiteSearch from './index';

interface SiteSearchContextReturnable {
    visible: boolean
    setVisible: (visible: boolean) => void
}

const SiteSearchContext = React.createContext<SiteSearchContextReturnable>(
    null as unknown as SiteSearchContextReturnable,
);

const SiteSearchProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [visible, setVisible] = React.useState<boolean>(false);


    const context = React.useMemo(() => ({
        visible,
        setVisible: (value: boolean) => {
            setVisible(value);
        },
    }), [visible]);

    return (
        <SiteSearchContext.Provider value={context}>
            {children}
            <SiteSearch />
        </SiteSearchContext.Provider>
    );
};

export const useSiteSearch = (): SiteSearchContextReturnable => {
    const data = React.useContext(SiteSearchContext);
    if (!data) {
        throw new Error('useSiteSearch used outside of context');
    }
    return data;
};

export default SiteSearchProvider;
