import React from 'react';

interface ScriptSet {
    [key: string]: () => Promise<unknown>
}

export enum LoadingStrategy {
    Hydrate = 'hydrate',
    Interaction = 'interaction'
}

interface ImportedReturnable {
    hasLoaded: boolean
    scripts: {
        [key: string]: undefined | unknown
    }
    load: () => Promise<void>
}

const useDynamicImports = (scripts: ScriptSet, strategy: LoadingStrategy): ImportedReturnable => {
    const [packageRefs, setPackageRefs] = React.useState<{ [key: string]: unknown }>({});
    const importsDepsUpdate = JSON.stringify(Object.keys(scripts));
    const hasLoaded = Object.keys(scripts).every(key => packageRefs[key]);
    const loadImports = React.useCallback(async (): Promise<void> => {
        const clonedRefs = { ...packageRefs };
        await Promise.all(Object.entries(scripts).map(async ([key, val]) => {
            if (!clonedRefs[key]) {
                const res = await val();
                clonedRefs[key] = res?.default || res;
            }
        }));
        setPackageRefs(clonedRefs);
        // eslint-disable-next-line
    }, [importsDepsUpdate]);

    React.useEffect(() => {
        if (strategy === LoadingStrategy.Hydrate) {
            loadImports();
        }
    }, [loadImports, strategy]);

    return {
        hasLoaded,
        scripts: packageRefs,
        load: loadImports,
    };
};

export default useDynamicImports;
