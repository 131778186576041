import React from 'react';
import { colors } from '../primitives/colors'
const CloseButton = props => (
    <button
        css={{
            color: colors.grey300,
            position: 'absolute',
            width: '32px',
            height: '32px',
            zIndex: 30,
            '&:focus:not(:focus-visible)': {
                outline: 'none',
            },
        }}
        {...props}
    >
        <div
            css={{
                width: '2px',
                height: '30px',
                position: 'absolute',
                top: '0',
                right: '50%',
                transform: 'rotate(45deg)',
                background: 'currentColor',
            }}
        />
        <div
            css={{
                width: '2px',
                height: '30px',
                position: 'absolute',
                top: '0',
                right: '50%',
                transform: 'rotate(-45deg)',
                background: 'currentColor',
            }}
        />
    </button>
);

export default CloseButton;
