import React from 'react';
import { PageContext } from 'gatsby/internal';
import { useLocation } from '@reach/router';
import matchPageTemplate, { PageTemplate } from '../utils/match-page-template';

interface TemplateProviderReturnable {
    template: PageTemplate | undefined
}

const TemplateContext = React.createContext(null as unknown as TemplateProviderReturnable);

const TemplateProvider = ({ children, pageContext }: { children: React.ReactNode, pageContext: PageContext }): JSX.Element => {
    const value = React.useMemo(() => ({
        template: matchPageTemplate(pageContext?.templateName),
    }), [pageContext?.templateName]);
    return (
        <TemplateContext.Provider value={value}>
            {children}
        </TemplateContext.Provider>
    );
};

export const useTemplateProvider = (): TemplateProviderReturnable => {
    const ctx = React.useContext(TemplateContext);
    if (!ctx) {
        throw new Error('No Template Context has been provided.');
    }
    return ctx;
};

export default TemplateProvider;
