import React from 'react';
import { useGridContext } from './context';

type Container = HtmlPropsNoRef<HTMLDivElement> & {
    removeAt?: string
    canStack?: boolean
};

const ContainerContext = React.createContext(false);

const Container = ({ removeAt, canStack, ...props }: Container): JSX.Element => {
    const { breakpoints: outerBreakpoints, maxWidth } = useGridContext();
    const inContainer = React.useContext(ContainerContext);
    const shouldBuildStyles = (!inContainer || (inContainer && canStack));

    const css = React.useMemo(() => {
        let hasRemoved = false;
        return shouldBuildStyles ? {
            margin: 'auto',
            ...Object.fromEntries(Object.entries(outerBreakpoints).map(([breakpointName, { query, gap }]) => {
                if (hasRemoved || breakpointName === removeAt) {
                    hasRemoved = true;
                    return [query, {
                        maxWidth: '100%',
                        paddingLeft: '0',
                        paddingRight: '0',
                    }];
                }
                return [query, {
                    width: '100%',
                    maxWidth: `calc(${maxWidth}px + ${gap * 2}px)`,
                    paddingLeft: `${gap}px`,
                    paddingRight: `${gap}px`,
                }];
            })),
        } : {};
    }, [maxWidth, outerBreakpoints, removeAt]);

    return (
        <ContainerContext.Provider value>
            <div
                css={css}
                {...props}
            />
        </ContainerContext.Provider>
    );
};

export default Container;
