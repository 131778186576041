import { useQuery, useQueries, UseQueryResult } from 'react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withPrefix } from 'gatsby-link';
import React from 'react';
import triggerInvoca from '../components/utils/trigger-invoca';
import useUserIdentifier from './use-user-identifier';

type UseNearbyFranchisesProps = {
    latitude: number
    longitude: number
    country: string | null
    locationType: string | null
    zip: string | null
    franchiseId: string | null
    provider: string | null
} | {
    latitude: number
    longitude: number
    locationType: string | null
    zip: string | null
    country: string | null
    provider: string | null
} | {
    address: string
    provider: string | null
} | null;

export type UseNearbyFranchiseSingleItem = UseQueryResult<Queries.Franchise, unknown>;

export type UseNearbyFranchiseMultipleItems = UseNearbyFranchiseSingleItem[];

interface CallToAction {
    title: string
    href: string
}

export interface FranchiseEndpointAnnouncement {
    id?: string,
    copy?: string,
    search_box_copy?: string,
    display_by_state?: string[],
    display_by_county?: string
    enable_temp_out_of_service?: boolean,
    call_to_action: CallToAction
    franchise_microsite_temp_out_of_service_copy?: string
}

export interface UseNearbyFranchisesReturnable {
    isLoading: boolean
    error: unknown
    data: UseNearbyFranchiseMultipleItems
    announcements: FranchiseEndpointAnnouncement[]
    latitude: string | null
    longitude: string | null;
}

interface EndpointData {
    latitude: string | null
    longitude: string | null;
    franchises: string[]
    announcements: string[]
}

export const fetchFranchiseData = (props: UseNearbyFranchisesProps, userId?: number|null): Promise<EndpointData> | null => {
    try {
        let queryUrl = '';

        if (props && (('latitude' in props && 'longitude' in props) || 'address' in props)) {
            queryUrl = withPrefix(`/api/franchise-v3?${Object.entries({ ...props, id: userId }).map(([k, v]) => {
                if (v) {
                    return `${k}=${v}`;
                }
                return '';
            }).filter(x => !!x).join('&')}`);
        }

        if (queryUrl) {
            return fetch(queryUrl)
                .then(res => res.json());
        }
    } catch (e) {
        return null;
    }
    return null;
};

const useNearbyFranchises = (props: UseNearbyFranchisesProps|null = null): UseNearbyFranchisesReturnable => {
    if (process.env.NODE_ENV === 'development') {
        const invariant1 = props ? 'latitude' in props && typeof props?.latitude !== 'number' : false;
        const invariant2 = props ? 'longitude' in props && typeof props?.longitude !== 'number' : false;
        const invariant3 = props ? 'address' in props && typeof props?.address !== 'string' : false;

        if (invariant1 || invariant2 || invariant3) {
            console.log('Invariant Error', props, invariant1, invariant2, invariant3);
            throw new Error('Invariant Error: Incorrect Format for useNearbyFranchises');
        }
    }

    const userId = useUserIdentifier();

    const { isLoading: isGetNearbyListLoading, error, data: foundFranchiseData } = useQuery({
        queryKey: props ? Object.values({ ...props, id: userId }) : ['recommended-franchises'],
        queryFn: (): Promise<EndpointData> | EndpointData => {
            const franchiseData = fetchFranchiseData(props, userId);

            if (franchiseData) {
                return franchiseData;
            }

            return {
                latitude: null,
                longitude: null,
                franchises: [],
                announcements: [],
            };
        },
    });

    const franchiseIdList = foundFranchiseData?.franchises;

    /*
    * Include the forced Franchise ID as first item if the user has manually picked one.
    * This way we can serve the Franchise ID selected as first if it exists, and if it doesn't,
    * We still have 5 other franchises to fall back on with the saved geo data for that franchisee.
    * */
    const franchiseIdListWithOptionalOverride = (
        props && 'franchiseId' in props && props.franchiseId
    ) ? [props.franchiseId, ...(franchiseIdList || []).filter(x => x !== props.franchiseId)] : franchiseIdList;

    const franchises = useQueries<Queries.Franchise[]>(
        (franchiseIdListWithOptionalOverride || []).map(franchiseId => ({
            queryKey: ['franchise', franchiseId.toString()],
            queryFn: () => fetch(`/franchises/single/${franchiseId}.json`)
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return null;
                }),
        })),
    );

    /*
    * Abstracted logic for filtering franchises from data
    * added the Object.keys test as passing a franchise number that is not in /franchises/single/
    * still returns a successful query and a data object (albeit empty)
    * this breaks the locations-index page and messes us the UI for the drawer
    */
    // @ts-ignore
    const filteredFranchises = franchises.filter(x => !!x?.data && !!Object.keys(x.data).length).filter(x => !x?.data?.tempOutOfService) as UseQueryResult<Queries.Franchise, unknown>[];
    
    /*
    * franchises list is still loading if:
    * 1 - there's a least one franchise in franchiseIdListWithOptionalOverride
    * AND
    * 2 - EITHER
    *   2.a - the lengths of fanchises and franchiseIdListWithOptionalOverride differ
    *   OR
    *   2.b - lengths are the same, but at least one franchise in franchises is still "loading"
    */
    const isFranchisesListLoading = !!franchiseIdListWithOptionalOverride?.length && ((franchiseIdListWithOptionalOverride.length !== franchises.length) || franchises.some(x => x?.status === 'loading'));

    // isLoading remains true as long as either query is still 'loading'
    const isLoading = isGetNearbyListLoading || isFranchisesListLoading;
    
    const comparisonPoint = JSON.stringify(franchises);

    /* TODO: 
    * not sure we need comparisonPoint in the dependencies arr since the isFranchisesListLoading will capture changes
    * also:
    * 1 - we could pass 'isLoading' as an arg (after the time) to setTimeout 
    * then, only trigger invoca it's not loading.
    * 2 - tested this locally without settimeout (triggering invoca in the useEffect) and it worked fine.
    * 
    * left it unchanged because not within the scope of this PR. thoughts?
    */
    React.useEffect(() => {
        setTimeout(() => {
            triggerInvoca();
        }, 300);
    }, [isLoading, comparisonPoint]);

    return {
        isLoading,
        error,
        data: filteredFranchises,
        announcements: foundFranchiseData?.announcements || [],
        latitude: foundFranchiseData?.latitude || null,
        longitude: foundFranchiseData?.longitude || null,
    };
};

export default useNearbyFranchises;
