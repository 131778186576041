/*global Invoca*/
import isBrowser from '@utils/is-browser'
// eslint-disable-next-line no-confusing-arrow
const triggerInvoca = () => {
    if (isBrowser() && !!window?.Invoca) {
        Invoca.PNAPI.run()
    }
}

export default triggerInvoca
