export enum PageTemplate {
    HyperLocal = 'hyper-local',
    Metro = 'metro',
    MetroState = 'metro-state',
    MicrositePage = 'microsite-page',
    MicrositeService = 'microsite-service',
    DefaultPageBuilder = 'default-page-builder',
    BlogPost = 'blog-post',
    PressRelease = 'press-release',
    ResourceIndex = 'resource-index',
    Leadership = 'leadership',
    GlossaryTerm = 'glossary-term',
    LocationsIndex = 'locations-index'
}

// This is to correct types more than anything
const matchPageTemplate = (templateName: string|undefined): PageTemplate | undefined => {
    if (templateName) {
        const hasValue = Object.values(PageTemplate).includes(templateName as PageTemplate);
        if (hasValue) {
            return templateName as PageTemplate;
        }
    }
    return undefined;
};

export default matchPageTemplate;

