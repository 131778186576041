import qs from 'qs';
import { useLocation } from '@reach/router';

const useQueryData = (to = ''): [string, string, string] => {
    const { pathname } = useLocation();
    const queryParts = to.split(/(?=[(?#)])/);

    const path = queryParts.find(x => x[0] !== '?' && x[0] !== '#') || (to !== '' ? pathname : '');
    const query = queryParts.find(x => x[0] === '?');
    const hash = queryParts.find(x => x[0] === '#');

    const linkQueryString = qs.parse((query || '').replace('?', ''));
    const builtQueryString = qs.stringify({ ...linkQueryString }) as string;

    // path, querystring, hash
    return [path, builtQueryString ? `?${builtQueryString}` : '', hash || ''];
};

export default useQueryData;
