import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withPrefix } from 'gatsby-link';
import useLocalStorageState from '../../hooks/use-local-storage-state';
import useNearbyFranchises, { FranchiseEndpointAnnouncement } from '../../hooks/use-nearby-franchises';
import type {
    UseNearbyFranchiseMultipleItems,
    UseNearbyFranchiseSingleItem,
} from '../../hooks/use-nearby-franchises';
import { PHONE_NUMBER } from '../../constants/constants';
import getCountryISO3 from 'country-iso-2-to-3';

const LOCAL_GEO_KEY = 'serv_geo_v4';

enum Provider {
    NETLIFY = 'netlify',
    IPSTACK = 'ipStack',
    NAVIGATOR = 'navigator',
    YEXT = 'yext',
}

const yextToGeo = (
    yext: Queries.FranchiseYext,
    franchiseId: string,
    latitude: number,
    longitude: number,
): UserLocationData | null => {
    if (!yext) { return null; }

    if (yext && (!latitude || !longitude)) {
        throw new Error('You must provide the lat/lng returned by useNearbyFranchises to persist this data');
    }

    if (
        yext?.address?.city
        && typeof yext?.cityCoordinate?.latitude === 'number'
        && typeof yext?.cityCoordinate?.longitude === 'number'
        && yext?.address?.region
        && yext?.address?.countryCode
    ) {
        return {
            provider: Provider.YEXT,
            city: yext?.address?.city,
            latitude,
            longitude,
            state: yext.address.region,
            stateShort: yext.address.region,
            country: yext.address.countryCode,
            franchiseId,
            zip: yext?.address?.postalCode || '',
        };
    }

    return null;
};

interface UserLocationData {
    provider: Provider
    city: string
    state: string
    stateShort: string
    country: string
    latitude: number
    longitude: number
    zip?: string
    // This should only be used for User Set queries
    franchiseId: string | null
}

interface UserLocationDataSet {
    geolocationApi: UserLocationData | null
    navigator: UserLocationData | null
    userSelectedFranchiseLocation: UserLocationData | null
    userSelectedGeoLocation: UserLocationData | null
    locationChangedByUser: boolean
}

const runIpStackQuery = async (): Promise<UserLocationData | null> => {
    let ipGeo = {};

    try {
        ipGeo = await (await fetch('https://api.ipstack.com/check?access_key=796435b41d113bb2ce49c3598fff5fba')).json();
    } catch (e) {
        return null;
    }

    const {
        latitude, longitude, city, region_code, region_name, country_code
    } = (ipGeo || {}) as any;

    // only relevant while we decide which location detection provider to use
    if (latitude && longitude) {
        return {
            provider: Provider.IPSTACK,
            latitude,
            longitude,
            city,
            stateShort: region_code,
            country: getCountryISO3(country_code) || country_code,
            state: region_name,
            franchiseId: null,
        };
    }
    return null;
};

// once we choose a location detection provider (and the fallback) will delete the one we don't use
const runNetlifyQuery = async (): Promise<UserLocationData | null> => {
    let netlifyGeo = {};

    try {
        netlifyGeo = await (await fetch(withPrefix('/geo/edge'))).json();
    } catch (e) {
        return runIpStackQuery();
    }

    const { latitude, longitude, city, subdivision, country } = (netlifyGeo || {}) as any;
    // only relevant while we decide which location detection provider to use
    if (latitude && longitude) {
        // below is to overocme the scenario where netlify returns lat and long without a city (which, sometimes happens)
        let reverseGeocodeLocation;
        if (!city || !subdivision?.code || !subdivision?.name) {
            try {
                reverseGeocodeLocation = await (await fetch(
                    withPrefix(`/api/geocode?latitude=${latitude}&longitude=${longitude}`),
                )).json();
            } catch (e) {
                return null;
            }
        }

        return {
            provider: Provider.NETLIFY,
            latitude,
            longitude,
            city: reverseGeocodeLocation?.city || city,
            stateShort: reverseGeocodeLocation?.stateShort || subdivision?.code,
            country: getCountryISO3(country?.code) || country?.code,
            state: reverseGeocodeLocation?.state || subdivision?.name,
            franchiseId: null,
        };
    }
    return null;
};

// once we choose a location detection provider (and the fallback) will delete the one we don't use
const runNavigatorQuery = async (position: GeolocationPosition): Promise<UserLocationData | null> => {
    const { coords } = position;
    if (!coords || !coords?.latitude || !coords?.longitude) {
        return null;
    }
    try {
        return await (await fetch(
            withPrefix(`/api/geocode?latitude=${coords.latitude}&longitude=${coords.longitude}`),
        )).json();
    } catch (e) {
        return null;
    }
};

interface LocatorProviderReturnable {
    loading: boolean,
    geo: UserLocationData | null,
    nearby: UseNearbyFranchiseMultipleItems,
    franchise: UseNearbyFranchiseSingleItem,
    setFranchise: (
        franchise: Queries.Franchise,
        latitude: number,
        longitude: number,
        userLocation: UserLocationData | null
    ) => void,
    geoDerivedFromOverride: boolean, // remove this
    locationChangedByUser: boolean,
    isNationalCallCenter: boolean
    announcements: FranchiseEndpointAnnouncement[]
}

// Force casting here to make sure context consumers will throw if used outside a provider.
const LocatorContext = React.createContext<LocatorProviderReturnable>(
    null as unknown as LocatorProviderReturnable,
);

// This function should probably be renamed, since it also bundles franchisees and can do overrides
const LocatorProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    /*
    * Get localstorage if it's available,
    * else return null
    * */
    const {
        setData: setNavPromptShown,
        getData: getNavPromptShown,
    } = useLocalStorageState<boolean>(
        'navigator_prompt_shown',
        async (): Promise<boolean> => false,
        [],
        (state: boolean): number => {
            if (state) {
                return 72;
            }
            return 24;
        },
    );

    const {
        data: geoData,
        setData: setGeoData,
        loading: isLoading,
        getData: getSavedGeoBeforeFinishedLoading,
    } = useLocalStorageState<UserLocationDataSet | null>(
        LOCAL_GEO_KEY,
        async (): Promise<UserLocationDataSet> => {
            /*
            * This will run only on initial visit, or
            * again if the localStorage time has expired
            * */
            const locator = await runNetlifyQuery();

            return {
                geolocationApi: locator || null,
                navigator: null,
                userSelectedFranchiseLocation: null,
                userSelectedGeoLocation: null,
                locationChangedByUser: false,
            };
        },
        [],
        (parsedData: UserLocationDataSet | null): number => {
            if (parsedData) {
                if (parsedData.locationChangedByUser) {
                    return 72;
                }
                return 24;
            }
            return 0;
        },
        (parsedData: UserLocationDataSet | null): boolean => !!parsedData?.locationChangedByUser,
    );

    const applyNavigatorQuery = React.useCallback(async (position: GeolocationPosition): Promise<void> => {
        const result = await runNavigatorQuery(position);
        if (result) {
            setGeoData({
                geolocationApi: geoData?.geolocationApi || null,
                navigator: { ...result, provider: Provider.NAVIGATOR, franchiseId: null },
                userSelectedFranchiseLocation: geoData?.userSelectedFranchiseLocation || null,
                userSelectedGeoLocation: geoData?.userSelectedGeoLocation || null,
                locationChangedByUser: geoData?.locationChangedByUser || false,
            });
        }
    }, [
        geoData?.locationChangedByUser,
        geoData?.geolocationApi,
        geoData?.userSelectedGeoLocation,
        geoData?.userSelectedFranchiseLocation,
        setGeoData,
    ]);

    React.useEffect(() => {
        /*
        * If the user has no geo data, prompt;
        * Or - if the use has geo data, but has not got navigator geo data
        * (and has not manually selected a franchise), prompt again
        * */

        const geoDataToUse = geoData || getSavedGeoBeforeFinishedLoading();
        const navPromptShown = getNavPromptShown();
        if (!geoDataToUse || (!geoDataToUse?.navigator && !navPromptShown)) {
            navigator.geolocation.getCurrentPosition(
                applyNavigatorQuery,
                (error) => {
                    // if it fails, we add an event to the dataLayer
                    if ('dataLayer' in window && Array.isArray(window.dataLayer) && !window.dataLayer.find(item => item.event === 'LocationPermission' && !item.allowed)) {
                        window.dataLayer.push({
                            event: 'LocationPermission',
                            allowed: false,
                            error: error.message,
                        });
                    }
                },
                {
                    enableHighAccuracy: true,
                },
            );
            setNavPromptShown(true);
        }
    }, [geoData, applyNavigatorQuery, getSavedGeoBeforeFinishedLoading, getNavPromptShown, setNavPromptShown]);

    // Get the most appropriate geo provider result
    const geoToUse = (() => {
        if (geoData?.locationChangedByUser && geoData?.userSelectedFranchiseLocation) {
            return geoData.userSelectedFranchiseLocation;
        }
        if (geoData?.navigator) {
            return geoData.navigator;
        }
        if (geoData?.geolocationApi) {
            return geoData?.geolocationApi;
        }
        return null;
    })();

    // Get the lat+lng from the most appropriate geo provider
    const nearbyQuery = geoToUse ? {
        latitude: Number(geoToUse.latitude),
        longitude: Number(geoToUse.longitude),
        franchiseId: geoToUse.franchiseId || null,
        provider: geoToUse.provider as string || null,
        country: geoToUse.country as string || null,
    } : null;

    // Find franchisees based on the lat+lng available
    const {
        isLoading: isLoadingFranchises,
        data: nearbyFranchises,
        announcements,
    } = useNearbyFranchises(nearbyQuery);

    // User is choosing a different location to the ones selected for them,
    // Create their Geo data from the Yext of the franchise
    // Even though we type-check the Yext existing, it should always exist,
    // As we do not build any franchises or franchise json if the Yext is missing
    const setFranchiseExternal = React.useCallback((
        newFranchise: Queries.Franchise,
        latitude: number,
        longitude: number,
        userLocation: UserLocationData | null,
    ): void => {
        if (newFranchise?.yext && newFranchise.franchiseNumber) {
            const userSelectionData = yextToGeo(
                newFranchise?.yext,
                String(newFranchise.franchiseNumber),
                latitude,
                longitude,
            );

            setGeoData({
                geolocationApi: geoData?.geolocationApi || null,
                navigator: geoData?.navigator || null,
                userSelectedFranchiseLocation: userSelectionData,
                userSelectedGeoLocation: userLocation,
                locationChangedByUser: true,
            });
        }
    }, [geoData, setGeoData]);

    const value = React.useMemo(() => ({
        loading: (() => {
            if (!geoToUse) {
                return true
            }
            return (isLoading || isLoadingFranchises)
        })(),
        geo: geoToUse,
        nearby: nearbyFranchises,
        franchise: nearbyFranchises[0] || {
            error: null,
            isLoading: isLoading || isLoadingFranchises,
            data: {
                id: 'NATIONAL_CALL_CENTER', // This may get forwarded to Invoca?
                yext: {
                    name: 'National Call Center',
                    mainPhone: PHONE_NUMBER,
                },
            },
        },
        setFranchise: setFranchiseExternal,
        geoDerivedFromOverride: false, // remove this
        locationChangedByUser: geoData?.locationChangedByUser || false,
        isNationalCallCenter: !nearbyFranchises[0],
        announcements: announcements || [],
    }), [
        geoData?.locationChangedByUser,
        geoToUse,
        isLoading,
        isLoadingFranchises,
        nearbyFranchises,
        setFranchiseExternal,
        announcements,
    ]);

    return (
        <LocatorContext.Provider value={value}>
            {children}
        </LocatorContext.Provider>
    );
};

export default LocatorProvider;

export const useLocator = (): LocatorProviderReturnable => {
    const data = React.useContext(LocatorContext);
    if (!data) {
        throw new Error('useLocator used outside of context');
    }
    return data;
};
