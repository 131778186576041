import ArrowDownIcon from '@images/svg/arrow-down.svg';
import AlgoliaSearch from 'algoliasearch';
import queryString from 'query-string';
import React from 'react';
import { useSiteSearch } from './provider';
import CloseButton from '../../atoms/CloseButton';
import Container from '../../atoms/Container';
import { Overlay, OverlayContent } from '../../primitives/overlay';
import Col from '../../primitives/grid/col';
import Row from '../../primitives/grid/row';
import Typography from '../../primitives/typography';
import { colors } from '../../primitives/colors';
import useDynamicImports, { LoadingStrategy } from '../../../hooks/use-dynamic-imports';

const FILTERS = [
    { id: 'all', name: 'All', indexName: null },
    { id: 'pages', name: 'SERVPRO.com', indexName: 'search_pages_v2_dev' },
    { id: 'services', name: 'Services', indexName: 'search_services_v2_dev' },
    { id: 'articles', name: 'Articles', indexName: 'search_articles_v2_dev' },
    { id: 'press_releases', name: 'Press Releases', indexName: 'search_press_v2_dev' },
    { id: 'faqs', name: 'FAQs', indexName: 'search_faqs_v2_dev' },
    {
        id: 'glossary_terms',
        name: 'Glossary Terms',
        indexName: 'search_glossaries_v2_dev',
    },
];

const SiteSearch = (): JSX.Element => {
    const {
        hasLoaded,
        scripts: {
            algoliaClient,
            Configure,
            InstantSearch,
            SearchInput,
            Index,
            Results,
        },
        load,
    } = useDynamicImports({
        algoliaClient: async () => {
            const search = await import('algoliasearch');
            return search.default(
                'SXQTSMSLU9',
                'ee5fa3035d420655ffa33ec9bd348ac1',
            );
        },
        Configure: async () => {
            const { Configure: cfg } = await import('react-instantsearch-dom');
            return cfg;
        },
        InstantSearch: async () => {
            const { InstantSearch: instantSearch } = await import('react-instantsearch-dom');
            return instantSearch;
        },
        Index: async () => {
            const { Index: index } = await import('react-instantsearch-dom');
            return index;
        },
        Results: async () => import('./results'),
        SearchInput: async () => import('./search-input'),
    }, LoadingStrategy.Interaction);

    const { visible, setVisible } = useSiteSearch();

    React.useEffect(() => {
        if (visible && !hasLoaded) {
            load()
        }
    }, [visible, hasLoaded, load])

    const containerRef = React.useRef(null);
    const inputRef = React.useRef(null);

    const [currentFilterId, setCurrentFilterId] = React.useState('all');
    const [indicesInfo, setIndicesInfo] = React.useState(() => FILTERS.slice(1).reduce((acc, curr) => ({
        ...acc,
        [curr.indexName]: {
            isEmpty: false,
        },
    }), {}));
    const [filterDropdownOpened, setFilterDropdownOpened] = React.useState(false);

    const searchQueryKey = 'siteSearch';

    React.useEffect(() => {
        if (visible) {
            window.scrollTo(0, 0);
            setTimeout(() => {
                containerRef.current?.querySelector('input[type="search"]')?.focus();
            }, 0);
        }
    }, [visible]);

    // Needs fixing to be valid, leaving currently because their original hack works
    React.useEffect(() => {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString[searchQueryKey]) {
            setVisible(true);
        }
    }, []);

    React.useEffect(() => {
        const parsedQueryString = queryString.parse(window.location.search);
        if (visible) {
            const searchPart = queryString.stringify({
                ...parsedQueryString,
                [searchQueryKey]: true,
            });
            if (!parsedQueryString[searchQueryKey]) {
                window.history.pushState(
                    '',
                    'Site Search',
                    `${window.location.pathname}?${searchPart}`,
                );
            }
        } else if (parsedQueryString[searchQueryKey]) {
            const querySearchPart = queryString.stringify(
                Object.keys(parsedQueryString).reduce((acc, key) => {
                    if (key === searchQueryKey) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: parsedQueryString[key],
                    };
                }, {}),
            );
            window.history.pushState(
                '',
                '',
                `${window.location.pathname}${querySearchPart ? `?${querySearchPart}` : ''
                }`,
            );
        }
    }, [visible]);

    React.useEffect(() => {
        if (visible) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    }, [visible]);

    const activeFilter = React.useMemo(
        () => FILTERS.find(item => item.id === currentFilterId),
        [currentFilterId],
    );

    const isEmpty = (): boolean => Object.keys(indicesInfo).every(key => indicesInfo[key]?.isEmpty);

    return (
        <Overlay open={visible}>
            <OverlayContent css={styles.siteSearchWrap}>

                <Container>
                    <Row css={styles.siteSearchTitle}>
                        <Col breakpoints={{ dt: { span: 10 }, mb: { span: 3} }}>
                            <Typography
                                fontSize={{
                                    dt: '3xl',
                                    tb: 'xl',
                                }}
                                fontWeight="medium"
                                as="h2"
                            >
                                How can we help you?
                            </Typography>
                        </Col>
                        <Col
                            breakpoints={{ dt: { span: 2 }, mb: { span: 1} }}
                            css={styles.closeButtonWrap}
                        >
                            <CloseButton
                                onClick={() => setVisible(false)}
                            />
                        </Col>
                    </Row>
                    <div css={styles.dropdownWrap}>
                        <div
                            css={styles.dropdownArrowWrap}
                            onClick={() => setFilterDropdownOpened(state => !state)}
                        >
                            {activeFilter ? activeFilter.name : 'All'}
                            <ArrowDownIcon css={styles.dropdownArrow(filterDropdownOpened)} />
                        </div>
                        <div
                            css={styles.optionsOuterWrap(filterDropdownOpened)}
                        >
                            <div
                                css={styles.optionsInnerWrap}

                            >
                                {FILTERS.map(item => (
                                    <div
                                        key={item.id}

                                        css={styles.dropdownOptionWrap(item.id === currentFilterId)}
                                    >
                                        <button
                                            css={styles.dropdownOption}
                                            onClick={() => {
                                                setCurrentFilterId(item.id);
                                                setFilterDropdownOpened(false);
                                            }}
                                        >
                                            {item.name}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {hasLoaded && (<InstantSearch
                        indexName="search_services"
                        searchClient={algoliaClient}
                    >
                        <SearchInput />

                        <div css={styles.results}>
                            {isEmpty() && <div css={styles.noResults}>No results</div>}
                            {FILTERS.filter((filter) => {
                                if (filter.id === 'all') {
                                    return false;
                                }
                                if (currentFilterId === 'all') {
                                    return true;
                                }
                                return filter.id === currentFilterId;
                            }).map(filter => (
                                <div key={filter.id}>
                                    <Index indexName={filter.indexName}>
                                        <Results
                                            title={filter.name}
                                            indexName={filter.indexName}
                                            setIndicesInfo={setIndicesInfo}
                                        />
                                        <Configure
                                            hitsPerPage={currentFilterId === 'all' ? 6 : 18}
                                            attributesToSnippet={['title', 'description', 'content']}
                                            distinct
                                        />
                                    </Index>
                                </div>
                            ))}
                        </div>
                    </InstantSearch>)}
                </Container>
            </OverlayContent>
        </Overlay>
    );
};

const styles = {
    siteSearchTitle: {
        margin: '48px 0',
    },
    siteSearchWrap: {
        maxWidth: '100%',
        width: '100%',
        height: '100vh',
    },
    closeButtonWrap: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    noResults: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        fontWeight: 500,
    },
    results: {
        marginTop: '2.5rem',
        '@media (min-width: 1024px)': {
            marginTop: '4rem',
        },
        '> :not([hidden]) ~ :not([hidden])': {
            marginTop: '4rem',
        },
    },
    optionsInnerWrap: {
        marginBottom: '0.5rem',
        gap: '0.5rem',
        borderBottomWidth: '1px',
        '@media (min-width: 1024px)': {
            display: 'flex',
            paddingLeft: '0.5rem',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            borderBottomWidth: '0',
            '>:not([hidden])~:not([hidden])': {
                marginLeft: '1.25rem',
            },
        },
    },
    optionsOuterWrap: (filterDropdownOpened: boolean) => ({
        visibility: filterDropdownOpened ? 'visible' : 'hidden',
        position: 'absolute',
        left: '0',
        top: '100%',
        marginTop: '0.5rem',
        width: '100%',
        '@media (min-width: 1024px)': {
            position: 'static',
            visibility: 'visible',
        },
        '>:not([hidden])~:not([hidden])': {
            marginLeft: '1.25rem',
        },
    }),
    dropdownOptionWrap: (activeOption: boolean) => ({
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        backgroundColor: colors.shadesWhite,
        color: activeOption && colors.primaryOrange,
        pointerEvents: activeOption && 'none',
        '@media (min-width: 1024px)': {
            backgroundImage: 'none',
            borderLeftWidth: '0',
            borderRightWidth: '0',
            borderTopWidth: '0',
        },
    }),
    dropdownOption: {
        width: '100%',
        padding: '0.5rem 1rem',
        borderRadius: '0.25rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        textAlign: 'left',
        '@media (min-width: 1024px)': {
            padding: '0 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
        },
        ':focus': {
            boxShadow: '0 0 0 2px rgba(96, 165, 250, 1)',
        },
    },
    dropdownWrap: {
        position: 'relative',
        zIndex: 30,
    },
    dropdownArrowWrap: {
        display: 'flex',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: '0.5rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0.25rem',
        borderWidth: '1px',
        height: '2.5rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        backgroundColor: '#ffffff',
        '@media (min-width: 1024px)': {
            display: 'none',
        },
    },
    dropdownArrow: (filterDropdownOpened: boolean) => ({
        color: colors.primaryOrange,
        transition: 'transform .25s',
        transform: filterDropdownOpened && 'rotate(-180deg)',
        path: {
            fill: 'currentColor',
        },
    }),
};

export default SiteSearch;
