import React from 'react';
import clsx from 'clsx';
import Color from '../colors';
import type { ColorProps } from '../colors';
import { PolymorphicWithoutRef } from '../polymorphic';

const fontSizes = {
    xs: 'xs',
    sm: 'sm',
    base: 'base',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
    '3xl': '3xl',
    '4xl': '4xl',
    '5xl': '5xl',
    '6xl': '6xl',
    '7xl': '7xl',
};

const fontWeights = {
    regular: 'regular',
    bold: 'bold',
    medium: 'medium',
};

const TypographyContext = React.createContext({ override: false });

export const useTypographyOverride = (): boolean => {
    const ctx = React.useContext(TypographyContext);
    return ctx && ctx.override;
};

const Typography = <C extends React.ElementType = 'div'>({
    override,
    fontWeight,
    fontSize,
    className,
    reducedLetterSpacing,
    as,
    ...props
}: PolymorphicWithoutRef<C, {
    override?: boolean,
    fontSize?: keyof typeof fontSizes | { [key: string]: keyof typeof fontSizes },
    fontWeight?: keyof typeof fontWeights
    reducedLetterSpacing?: boolean
} & ColorProps>): JSX.Element => {
    const value = React.useMemo(() => ({ override: override || false }), [override]);
    return (
        <TypographyContext.Provider value={value}>
            <Color
                as={as as React.ElementType}
                {...props}
                className={clsx(
                    className,
                    reducedLetterSpacing && 'heading-lsp',
                    // Font size key could be string or number depending on typing of Font Size object
                    (fontSize && (typeof fontSize === 'number' || typeof fontSize === 'string')) && `dt-${fontSize}`,
                    ...(fontSize && typeof fontSize === 'object' ? Object.entries(fontSize)
                        .map(([breakpoint, selection]) => `${breakpoint}-${selection}`) : []),
                    fontWeight && fontWeights[fontWeight],
                )}
            />
        </TypographyContext.Provider>
    );
};

export default Typography;
