/* eslint-disable import/prefer-default-export */
/*
* This file is used for additional tokens (i.e. not Colors or Typography).
* Colors and Typography have their tokens inside their own primitives.
* */

export const borderRadius = {
    small: '3px',
    medium: '6px',
    large: '12px',
    full: '5000px',
};

export const breakpoints = {
    dt: '@media screen',
    tb: '@media (max-width: 1200px)',
    mb: '@media (max-width: 767px)',
};
