import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import { GatsbyLinkProps } from 'gatsby';
import Button, { ButtonProps, buttonStyles } from '../button';
import { colors } from '../colors';
import Link from "../link";

export const styledButtonStyles = {
    overflow: 'hidden',
    position: 'relative',
    paddingLeft: '24px',
    paddingRight: '52px',
    height: '44px',
    fontSize: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
    '@media (max-width: 767px)': {
        fontSize: '16px',
    },
    '&:hover:before': {
        backgroundColor: colors.primaryOrangeHover,
    },
    '&:hover:after': {
        backgroundColor: colors.primaryOrangeHover,
        boxShadow: `0 0 0 4px ${colors.primaryOrangeHover}`,
    },
    '&:before': {
        transition: '0.2s',
        width: 'calc(100% - 40px)',
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0',
        borderRadius: '500px 0 0 500px',
        backgroundColor: colors.primaryOrange,
        top: '0',
        bottom: '0',
        zIndex: '-1',
    },
    '&:after': {
        transition: '0.2s',
        width: '46px',
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '14px',
        top: '0',
        bottom: '0',
        backgroundColor: colors.primaryOrange,
        transform: 'rotate(45deg)',
        borderRight: `4px solid ${colors.shadesWhite}`,
        borderTop: `4px solid ${colors.shadesWhite}`,
        boxShadow: `0 0 0 4px ${colors.primaryOrange}`,
        zIndex: '-1',
    },
    fontWeight: 600,
    letterSpacing: '-0.1px',
    color: colors.shadesWhite,
    // zIndex: '1',
    '&:disabled': {
        '&:hover:before': {
            backgroundColor: colors.grey400,
        },
        '&:hover:after': {
            backgroundColor: colors.grey400,
            boxShadow: `0 0 0 4px ${colors.grey400}`,
        },
        '&:before': {
            backgroundColor: colors.grey400,
        },
        '&:after': {
            backgroundColor: colors.grey400,
            boxShadow: `0 0 0 4px ${colors.grey400}`,
        },
    }

} as Interpolation<Theme>;

const StyledButton = (props: NoRef<ButtonProps>): JSX.Element => (
    <Button
        css={styledButtonStyles}
        {...props}
    />
);

export const StyledButtonAsLink = (props: NoRef<GatsbyLinkProps<any>>): JSX.Element => (
    // @ts-ignore
    <Link
        css={{
            ...buttonStyles as any,
            ...styledButtonStyles as any,
        }}
        {...props}
    />
);

export default StyledButton;
