import React from 'react';
import { keyframes } from '@emotion/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import {
    DialogContentImplProps,
} from '@radix-ui/react-dialog';
import { colors } from '../colors';

/*
* A big full-screen modal component
* */

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const overlayHide = keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
});

export const Overlay = DialogPrimitive.Root;
export const OverlayTrigger = DialogPrimitive.Trigger;

/* This component houses the Overlay content */

export const OverlayContent = (
    { className, ...props }: CustomProps<DialogContentImplProps>,
): JSX.Element => (
    <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay css={{
            backgroundColor: colors.shadesWhite,
        }}
        >
            <DialogPrimitive.Content
                css={{
                    backgroundColor: colors.shadesWhite,
                    position: 'fixed',
                    zIndex: 2000000,
                    inset: 0,
                    overflowY: 'auto',
                    // display: 'grid',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    "&[data-state='open']": {
                        animation: `${overlayShow} 250ms forwards`,
                    },
                    "&[data-state='closed']": {
                        animation: `${overlayHide} 350ms forwards`,
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        animation: 'none!important',
                    },
                }}
                className={className}
                {...props}
            />
        </DialogPrimitive.Overlay>
    </DialogPrimitive.Portal>
);

/* this component closes the Overlay */
