import React from 'react';
import getBrowserFingerprint from 'get-browser-fingerprint';

const useUserIdentifier = (): number|null => {
    const [user, setUser] = React.useState<number|null>(null);
    React.useEffect(() => {
        const probableUser = getBrowserFingerprint({ enableScreen: true });
        if (probableUser) {
            setUser(
                probableUser as number,
            );
        }
    }, []);

    return user;
};

export default useUserIdentifier;
