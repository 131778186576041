const scrollTo = (hash) => {
    const scrollOffset = -200;
    const elementToScrollTo = document.getElementById(hash.substr(1));
    if (elementToScrollTo) {
        const top = elementToScrollTo.getBoundingClientRect().top + window.scrollY + scrollOffset;
        window.scrollTo({ top, behavior: 'smooth' });
    }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    // find if we've got a hash defined - if we do - scroll to it
    const hash = window.gatsby_router_hash;

    if (hash) {
        if (prevLocation?.pathname === location.pathname) {
            // Don't wait for transition if page is the same
            scrollTo(hash);
        } else {
            setTimeout(() => {
                scrollTo(hash);
            }, 300); // length of Framer exit transition
        }
        window.gatsby_router_hash = undefined;
    }
};

export const onClientEntry = () => {
    window.dataLayer = window.dataLayer || [];
}

export const onInitialClientRender = () => {
    const { hash } = window.location;
    if (hash) {
        setTimeout(() => {
            scrollTo(hash);
        }, 300);
    }
};

/*
 * If the pathnames are the same, and there is a router hash, don't use in-built page scroll updating
 * */
export const shouldUpdateScroll = ({
    prevRouterProps, routerProps,
}) => !(prevRouterProps?.location?.pathname === routerProps?.location?.pathname && window.gatsby_router_hash);
