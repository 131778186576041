/* eslint-disable import/no-cycle */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import useQueryData from './use-query-data';
import { useMicrosite } from '../../../contexts/franchise/microsite-context';

export type LinkProps = {
    to: string,
    onClick?:(event: React.MouseEvent<HTMLAnchorElement>) => void,
    children?: string | React.ReactNode | React.ReactNode[]
    className?: string
    style?: React.CSSProperties
};

const Link = ({
    to, children, onClick, ...props
}: LinkProps): JSX.Element => {
    const { permalink, isMicrosite } = useMicrosite();
    const [path, queryString, hash] = useQueryData(to);

    const linkRef = React.useRef<HTMLAnchorElement>(null);
    const linkIsExternal = path.includes(':');

    if (linkIsExternal) {
        return (
            <a
                ref={linkRef}
                href={to}
                rel="noreferrer noopener"
                target="_blank"
                {...props}
            >
                {children}
            </a>
        );
    }

    const pathPrefix = (() => {
        if (permalink && isMicrosite && path.startsWith('/') && !path.startsWith('/locations')) {
            return permalink;
        }
        return '';
    })();

    return (
        <GatsbyLink
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={linkRef}
            to={`${pathPrefix}${path.replace(pathPrefix, '')}${queryString}${hash}`}
            onClick={(e) => {
                if (hash) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.gatsby_router_hash = hash;
                }
                if (onClick) {
                    onClick(e);
                }
            }}
            {...props}
        >
            {children}
        </GatsbyLink>
    );
};
export default Link;
