export const PHONE_NUMBER = '+18007378776';
export const PHONE_NUMBER_DISPLAY = '1-800-SERVPRO';
export const TEN_MINUTES_IN_MS = 1000 * 60 * 10;
export const THREE_DAYS_IN_MS = 1000 * 60 * 60 * 72;

export const BREAKPOINT_LG = 1280;
export const BREAKPOINT_MD = 1024;
export const BREAKPOINT_SM = 769;
export const BREAKPOINT_XS = 321;

export const SOCIAL_URL_LINKEDIN = 'https://www.linkedin.com/company/servpro-industries/';
export const SOCIAL_URL_TWITTER = 'https://twitter.com/servpro';
export const SOCIAL_URL_YOUTUBE = 'https://www.youtube.com/user/SERVPROCorporate';
export const SOCIAL_URL_FACEBOOK = 'https://www.facebook.com/SERVPRO';

export const SITE_SEARCH_PLACEHOLDER = 'Search our site';
export const CHANGE_LOCATION = 'Update/change location';
export const FORMSPREE_TOKEN = 'xbjqblqv';
export const FORMSPREE_TOKEN_OWNERSHIP_FORM = 'xyyokdro';
export const YOUR_SELECTED_LOCATION = 'Your selected location is:';
export const YOUR_DETECTED_LOCATION = 'Your detected location is:';
export const LOCAL_CITY_LOCATION = 'Contact your local SERVPRO, serving:';
export const NATIONAL_CALL_CENTER_LOCATION = 'Talk to a representative now:';
export const NATIONAL_CALL_CENTER_DISPLAY = 'National Call Center';
export const AVERAGE_RATE_THRESHOLD = 4;